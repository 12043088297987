import { createToastInterface } from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Toast Notification Component Styles
// import '@core/scss/vue/libs/toastification.scss'

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
export const toastOptions = {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
}

const toastDefault = createToastInterface(toastOptions)

const propsDefault = { variant: 'success', title: '', text: '' }

export const toast = (props = {}) => {
  toastDefault({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      ...propsDefault,
      ...props,
    },
  })
}
