// ** Auth Endpoints
export default {
  loginEndpoint: 'api/login',
  registerEndpoint: 'api/register',
  refreshEndpoint: 'api/refresh-token',
  logoutEndpoint: 'api/logout',
  resetPasswordEndpoint: 'api/reset-password',

  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  storageTokenKeyName: '_accessToken',
  storageRefreshTokenKeyName: '_refreshToken',

  storageUserDataKeyName: '_userData',
}
